<template>
  <section>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">เพิ่มข้อมูล</h3>
        <div class="card-tools">
          <div class="input-group input-group-sm">
            <router-link to="/customers" class="btn btn-sm btn-warning ml-2" type="button"><i class="fas fa-backward fa-fw fa-fw"></i>ย้อนกลับ</router-link>
            <button class="btn btn-sm btn-success ml-2" @click.prevent="getResults()"  style=""><i class="fas fa-sync-alt fa-fw"></i>ล้างรายการ</button>
          </div>
        </div>
      </div>
      <form ref="form" @submit.prevent="createCustomer" autocomplete="off">
      <div class="card-body">
        <div class="row">
          <div class="form-group col-md-2 col-sm-1">
                <div class="col">
                  <label>คำนำหน้า</label>
                </div>
<!--                <Prefix @SetData="$v.form.custTitle.$model" :removes="remove" :editprefix="editprefix" :class="{ 'is-invalid':$v.form.custTitle.$error }"></Prefix>-->
                <Prefix @SetData="SetDataTitle" :remove="remove"  :class="{ 'is-invalid':$v.form.custTitle.$error }"></Prefix>
                <span v-if="!$v.form.custTitle.required " class="invalid-feedback offset-1">required</span>
              </div>
          <div class="form-group col-md-4 col-sm-1">
              <div class="col">
                <label>ชื่อ</label>
              </div>
                <input
                    v-model.trim="$v.form.custName.$model"
                    type="text"
                    class="form-control"
                    placeholder="ชื่อ"
                    :class="{ 'is-invalid':$v.form.custName.$error }"
                />
                <span v-if="!$v.form.custName.required " class="invalid-feedback">required</span>
                <span v-if="!$v.form.custName.minLength" class="invalid-feedback">จำนวนตัวอักษรน้อยกว่า 2 ตัวอักษร.</span>
            </div>
          <div class="form-group col-md-4 col-sm-1">
              <div class="col">
                <label>นามสกุล</label>
              </div>
                <input
                    v-model.trim="$v.form.custSurname.$model"
                    type="text"
                    class="form-control"
                    placeholder="นามสกุล"
                    :class="{ 'is-invalid':$v.form.custSurname.$error }"
                />
                <span v-if="!$v.form.custSurname.required " class="invalid-feedback">required</span>
                <span v-if="!$v.form.custSurname.minLength" class="invalid-feedback">จำนวนตัวอักษรน้อยกว่า 2 ตัวอักษร.</span>
            </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>ชื่อเล่น</label>
            </div>
            <input
                v-model.trim="form.custNickname"
                type="text"
                class="form-control"
                placeholder="ชื่อเล่น"
            />
          </div>
        </div>
        <div class="row">
          <div class="mt-md-3 mt-sm-1">
            <div class="col">
              <label></label>
            </div>
            <div class="col" style="padding-left: 40px">
              <input type="checkbox" @click="confirm">  <label>  ไม่ประสงค์ระบุบที่อยู่</label>
            </div>
          </div>
          <div class="mt-md-4 mt-sm-1 offset-1">
            <div class="form-group ">
              <div class="col offset-2">
                <label>ข่าวสาร</label>
              </div>
              <div class="col" style="padding-left: 40px">
                <select v-model="form.custNews" class="form-control" >
                  <option  :value="'True'">ต้องการข่าวสาร</option>
                  <option  :value="'False'">ไม่ต้องการข่าวสาร</option>
                </select>
              </div>
            </div>
          </div>
          <div class="mt-md-4 mt-sm-1 offset-1">
            <div class="col">
              <label>รหัสที่ต้องการใส่ **เฉพาะกรณีต้องการใส่หมายเลยที่ข้ามไปเท่านั้น**</label>
            </div>
            <input
                v-model="form.custNo"
                type="text"
                class="form-control "
                placeholder="รหัสลูกค้า"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="form-group col-md-4 col-sm-1">
                <div class="col">
                  <label>สถานที่</label>
                </div>
                <input
                    v-model="form.custPlace"
                    type="text"
                    class="form-control "
                    placeholder="สถานที่"
                />
              </div>
          <div class="form-group col-md-2 col-sm-1">
              <div class="col">
                <label>เลขที่</label>
              </div>
                <input
                    v-model.trim="$v.form.custAddress.$model"
                    type="text"
                    class="form-control"
                    placeholder="เลขที่"
                    :class="{ 'is-invalid':$v.form.custAddress.$error }"
                />
                <span v-if="!$v.form.custName.required " class="invalid-feedback">required</span>
            </div>
          <div class="form-group col-md-2 col-sm-1">
              <div class="col">
                <label>ตรอก/ซอย</label>
              </div>
                <input
                    v-model="form.custSoi"
                    type="text"
                    class="form-control"
                    placeholder="ตรอก/ซอย"
                />
            </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>ถนน</label>
            </div>
            <input
                v-model.trim="$v.form.custRoad.$model"
                type="text"
                class="form-control"
                placeholder="ถนน"
                :class="{ 'is-invalid':$v.form.custRoad.$error }"
            />
            <span v-if="!$v.form.custRoad.required " class="invalid-feedback">required</span>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-3 col-sm-1">
            <div class="col">
              <label>ตำบล/แขวง</label>
            </div>
            <District @SetData="SetDataDistricts" :remove="remove"  :class="{ 'is-invalid':$v.form.custDistricts.$error }"></District>
            <span v-if="!$v.form.custDistricts.required " class="invalid-feedback offset-1">required</span>
          </div>
          <div class="form-group col-md-3 col-sm-1">
            <div class="col">
              <label>อำเภอ/เขต</label>
            </div>
            <Amphurs @SetData="SetDataAmphurs" :remove="remove"  :class="{ 'is-invalid':$v.form.custAmphurs.$error }"></Amphurs>
            <span v-if="!$v.form.custAmphurs.required " class="invalid-feedback offset-1">required</span>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>จังหวัด</label>
            </div>
            <Province @SetData="SetDataProvince" :remove="remove"  :class="{ 'is-invalid':$v.form.custProvince.$error }"></Province>
            <span v-if="!$v.form.custProvince.required " class="invalid-feedback offset-1">required</span>
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>รหัสไปรษณีย์</label>
            </div>
            <input
                v-model.trim="$v.form.custZipcode.$model"
                type="text"
                class="form-control"
                placeholder="รหัสไปรษณีย์"
                :class="{ 'is-invalid':$v.form.custZipcode.$error }"
            />
            <span v-if="!$v.form.custZipcode.required " class="invalid-feedback">required</span>
            <span v-if="!$v.form.custZipcode.minLength" class="invalid-feedback">จำนวนตัวอักษรน้อยกว่า 5 ตัวอักษร.</span>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>โทรศัพท์บ้าน</label>
            </div>
            <input
                v-model.trim="form.custTel"
                type="text"
                class="form-control"
                placeholder="โทรศัพท์บ้าน"
            />
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>โทรศัพท์มือถือ</label>
            </div>
            <input
                v-model.trim="form.custMobile"
                type="text"
                class="form-control"
                placeholder="โทรศัพท์มือถือ"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>โทรศัพท์มือถือ 2</label>
            </div>
            <input
                v-model.trim="form.custMobile2"
                type="text"
                class="form-control"
                placeholder="โทรศัพท์มือถือ 2"
            />
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>ความสัมพันธ์</label>
            </div>
            <input
                v-model.trim="form.custRelation"
                type="text"
                class="form-control"
                placeholder="ความสัมพันธ์"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>กลุ่มเป้าหมาย</label>
            </div>
            <input
                v-model.trim="form.custTarget"
                type="text"
                class="form-control"
                placeholder="กลุ่มเป้าหมาย"
            />
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>สาขา</label>
            </div>
            <select v-model.trim="$v.form.companyId.$model" class="form-control" :class="{ 'is-invalid': $v.form.companyId.$error }">
              <option disabled value="">Please select one</option>
              <option v-for="c in company" :key="c.companyId" :value="c.companyId">
                {{c.comNameTh}}
              </option>
            </select>
            <span v-if="!$v.form.companyId.required " class="invalid-feedback">required</span>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <router-link to="/customers" class="btn btn-default float-left" type="button">Close</router-link>
        <button type="submit" class="btn btn-primary float-right">Add New</button>
      </div>
    </form>
    </div>
    <!-- /.card -->

  </section>
</template>

<script>
import axios from 'axios';
import { Form } from 'vform';
import { required, minLength  } from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import Prefix from "@/components/Prefix";
import District from "@/components/District";
import Amphurs from "@/components/Amphurs";
import Province from "@/components/Province";
import router from "@/router";
export default {
  name: 'CustomerCreate',
  components: {Province, Amphurs, District, Prefix},
  data(){
    return{
      data:{},
      company: '',
      remove:[],
      editprefix: '',
      toggle:true,


      form : new Form ({
        custId:'',
        companyId:'',
        custNo : '',
        custRecordData : '',
        custNews : 'True',
        custTitle : '',
        custName : '',
        custSurname : '',
        custNickname : '',
        custPlace : '',
        custAddress : '',
        custSoi : '',
        custRoad : '',
        custDistricts : '',
        custAmphurs : '',
        custProvince : '',
        custZipcode : '',
        custTel : '',
        custMobile : '',
        custMobile2 : '',
        custRelation : '',
        custBirthday : '',
        custTarget : '',
      })


    }
  },

  validations: {
    form : {
      companyId: {
        required,
      },
      custTitle: {
        required,
      },
      custName: {
        required,
        minLength: minLength(2)
      },
      custSurname: {
        required,
        minLength: minLength(2)
      },
      custAddress: {
        required,
      },
      custRoad: {
        required,
      },
      custDistricts: {
        required,
      },
      custAmphurs: {
        required,
      },
      custProvince: {
        required,
      },
      custZipcode: {
        required,
        minLength: minLength(5)
      },
    }

  },
  mounted() {
    this.getResults()
  },
  methods:{
    ...mapActions(['logout']),
    async getResults(){
          this.form.reset()
       await axios.get('api/company/getCompany')
           .then(response => {
             if (response.status === 200)
             this.company = response.data
           });
    },

    async confirm(){
      this.toggle = !this.toggle
      if (this.toggle === true){
          this.form.custPlace = ''
          this.form.custAddress = ''
          this.form.custSoi = ''
          this.form.custRoad = ''
          this.form.custDistricts = ''
          this.form.custAmphurs = ''
          this.form.custProvince = ''
          this.form.custZipcode = ''
      }else {
        this.form.custPlace = '-'
        this.form.custAddress = '-'
        this.form.custSoi = '-'
        this.form.custRoad = '-'
        this.form.custDistricts = '-'
        this.form.custAmphurs = '-'
        this.form.custProvince = '-'
        this.form.custZipcode = '00000'
      }
    },

    async createCustomer(){
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) return;
      // console.log(this.form)
      await this.form.post('api/customer/createCustomer')
          .then( async response => {
            if (response.data.success === true ){
              this.$swal('success', 'สร้างข้อมูลสำเร็จ เข้าสู้หน้าการทำรายการ', 'success');
              // this.toast('สร้างข้อมูลสำเร็จ')
              // this.form.clear()
              this.form.reset()
              this.$v.$reset()
              this.remove = []
              this.toggle = !this.toggle
              this.$nextTick( async () => {
                await this.$store.commit('set_custdata',response.data.data)
                router.push('/customerdetail');
              })
            }
          }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });
    },

    toast(msg){
      this.$swal({
        position: 'top-end',
        icon: 'success',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 1500
      })
    },

    SetDataTitle(data){
      this.form.custTitle = data
    },

    SetDataDistricts(data){
      this.form.custDistricts = data
    },

    SetDataAmphurs(data){
      this.form.custAmphurs = data
    },

    SetDataProvince(data){
      this.form.custProvince = data
    }


  }
}
</script>
