<template>
  <section>
    <div class="col-md-12 col-sm-1">
      <multi-select v-model="setdata" tag-placeholder="เลือกคำนำหน้า" :custom-label="nameWithLang"
                    placeholder="เลือกคำนำหน้า" label="name" track-by="prefixname"  :options="data"
                    @input="update" style="font-size: 12px"
      ></multi-select>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Prefix",
  data(){
    return{
      data:[],
      setdata:[],
    }
  },
  props: {
    remove: {
      type: Array,
      default: () => []
    },
    editprefix: {
    // type: String | Number | Boolean | Object | {Number, String, Object},
      type: String,
      default: ''
      // type: String
    },
  },
  watch: {
    remove: function() {
        this.setdata = []

    },
    editprefix:function () {
      let data = {
        'prefixname' : this.editprefix
      }
      axios.post('api/sysdata/findPrefix',data)
          .then(response => {
            this.setdata = response.data
          });
    }
  },
  computed:{

  },
  mounted(){
    this.getResults()
  },
  methods:{
    async getResults(){
      await axios.get('api/sysdata/getPrefix')
          .then(response => {
            this.data =response.data
            // this.setdata = response.data[185]
            // this.$emit("SetData", this.setdata.prefixname);
          });
    },

    nameWithLang({prefixname}){
      return `${prefixname}`
    },

    update(data){
      if (data != null) {
        this.$emit("SetData", data.prefixname);
      }
    },

  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
